.box {
    position: relative;
    width: 5.8%;
    margin: 1.5%;
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.marker {
    background: #f3f4fc ;
    text-align: center;
    margin: 1.5%;
    color: #626ed4;
    display: flex;
    border-radius: 6px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.marker-number {
    text-align: center;
    width: 100%;
}

.markersCont {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.icon {
    position: absolute;
    cursor: pointer;
    left: -9px;
    top: -9px;
    i {
        color: #626ed4;
        font-size: 18px;
    }
}
