.assign-content{
  .modal-content{
    width:42vw !important;
  } 
}
.switch-perm-block{

display: flex;
align-items: center;

}