legend{
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}
.radio{
    .form-check{
        label{
            font-weight: 100 !important;
        }
    }
}
.perm-action{
cursor: pointer;
font-size: 20px;
}
.vertical-center{

}
td{

    text-align: center;
    vertical-align: middle;
}
.def-perms-content{
    overflow: scroll;
    height: calc(100vh - 100px);
}
.active{
    z-index: 0;
}
#alert-over-all{
    position: absolute !important;
    z-index: 1111;
    top: 0;
}
i:hover{
    color: #626ed4;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}